.appoint-ment-detail-div{
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 2px dotted #1c324a;
}

.appt-as-guest-input-field{
    width: 100%;
    border: none;
    border-bottom: 2px solid #1c324a;
    padding: 5px;
    margin-bottom: 10px;
}

.appt-as-guest-input-field:focus{
    outline: none;
}