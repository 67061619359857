.custom-carousel-container {
    padding: 20px;
  }

  
  .card-of-feedback-med-spa{
    background: linear-gradient(45deg, #f3f4f6, #e5e7eb);
    padding: 40px;
    background-image: url("https://www.viomedspa.com/elements/quote_gray_2.png");
    background-repeat: no-repeat;
  }

  .three-card-carousel .carousel-control-prev,
  .three-card-carousel .carousel-control-next {
    display: none;
  }
  
  .three-card-carousel .carousel-indicators {
    justify-content: center;
  }
  
  .three-card-carousel .carousel-inner {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  
  .three-card-carousel .carousel-item {
    /* display: flex; */
    flex: 0 0 100%;
  }
  
  .three-card-carousel .half-card {
    flex: 0 0 50%;
    padding: 0 10px;
    transition: transform 0.5s;
  }
  
  .three-card-carousel .full-card {
    flex: 0 0 100%;
    /* padding: 0 10px; */
    transition: transform 0.5s;
  }
  
  .three-card-carousel .carousel-item.active .half-card {
    transform: scale(0.8);
    opacity: 0.6;
    transition: transform 0.5s;
  }
  
  .three-card-carousel .carousel-item.active .full-card {
    transform: scale(1);
    opacity: 1;
    transition: transform 0.5s;
  }
  .feed-med-spa-title{
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: rgb(28 50 74)
  }

  .feed-med-spa-title-user-name{
    color: rgb(28 50 74)
  }

  @media (max-width: 768px) {
    .half-card{
      display: none;
    }

    .card-of-feedback-med-spa{
      padding-top: 20px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }
  }