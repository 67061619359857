.ser-vices-section-one-bg{
    /* background-color: rgb(249 250 251 ); */
    background: linear-gradient(270deg, #e5e7eb, rgba(229,231,235,0));
    padding-top: 50px;
    padding-bottom: 50px;
}

.ser-vices-sec-one-img{
    height: 100%;
    width: 100%;
}

.custom-dropdown-button {
    background-color: rgb(255 255 255);
    border: 1px solid rgb(209 213 219);
    border-radius: 9999px;
    color: black; 
    font-size: .875rem;
    line-height: 1.25rem;
    box-shadow: 0 0 0 #0000, 0 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    width: 200px;
    text-align: left;
    position: relative;
  }
  
  .custom-dropdown-button:hover {
    border: 1px solid #1c324a;
    background-color: rgb(255 255 255);
    color: black; 
  }

  .custom-dropdown-button.btn-light:active {
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(209, 213, 219) !important;
    color: black !important; 
}

  .custom-dropdown-button::after {
    float: right;
    content: '';
    vertical-align: middle;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .clear-filters-text{
    color: #1c324a;
    margin: 0px;
    text-decoration: underline;
    cursor: pointer;
  }

  .services-card-body{
    height: 420px;
    border: 1px solid rgb(209 213 219);
    box-shadow: 0 0 0 #0000, 0 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

  .services-card-body:hover{
    box-shadow: 0 0 0 3px #1c324a;
    
  }

  .services-card-title{
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    margin: 0px;
    color: #1c324a;
  }

  .serv-ces-card-text{
    font-size: .875rem;
    line-height: 1.25rem;
    color: rgb(28 50 74)
  }

  .ser-vice-detail-duration{
    margin: 0px;
    color: #1c324a;
  }

  .ser-vice-details-bg-section{
    /* background: linear-gradient(97deg, #d9d9d9, hsla(0,0%,85%,0)); */
    background: linear-gradient(97deg, #959191,#ccc8c8, hsla(0,0%,85%,0));
  }

  .ser-vice-section-data-on-img{
    border-bottom: 3px solid rgb(255 255 255);
    padding-bottom: 40px;
    padding-top: 40px;
  }
  .ser-vice-section-data-on-img h1{
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    margin: 0px;
    color: rgb(255 255 255);
  }
  .ser-vice-section-data-on-img p{
    margin: 0px;
    color: rgb(255 255 255);
  }

  .service-details-bg-img{
    width: 100%;
  }

  .section-three-class-border{
    border: 2px solid rgb(229 231 235);
    padding: 20px;
  }

  .section-three-class-header{
    color: rgb(28 50 74);
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .section-three-class-para{
    color: rgb(28 50 74);
    margin: 0px;
  }

  .section-three-middle-col-border{
    border: 2px solid rgb(229 231 235);
    padding: 2.5rem ;
    border-radius: 1rem
  }

  .flex-of-image-logo{
    align-items: center;
    gap: 10px;
    padding-bottom: 1.25rem;
    margin: 0px;
  }
  .section-three-card-inside-logos{
    width: 3rem;
  height: 3rem;
  cursor: pointer;
  }

  .dr-faq-width-div{
    /* padding-bottom: 0.5rem;
    padding-top: 0.5rem; */
  }
  
.dr-faq-main-div{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
   
  }
  .dr-faq-main-div:hover{
    background-color: rgb(82 126 183);
    /* margin-bottom: 5px; */
    border-radius: 1rem;
  }
.dr-faq-flex-div{
  padding: 10px;
  padding-top: 0px;
}

.dr-faq-main-div h3{
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: rgb(255 255 255);
  margin: 0px;
  font-weight: 400;
}

.dr-faq-flex-div p{
  margin: 0px;
  color: rgb(255 255 255);
}