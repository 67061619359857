.appoint-ment-slots-menu{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
}

.select-time-slot-button{
    background-color: rgb(6, 182, 179);
}

.select-time-slot-button:hover{
    background-color: #8fb3da;
}


