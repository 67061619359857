.wel-come-staff-info{
    color: rgb(28 50 74);
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    font-size: 1.5rem;
}

.med-spa-staff-detail-image{
height: 350px;
width: 450px;
border-radius: 80px;
}

.staf-header-detals-div{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.header-of-staff-name{
margin: 0px;
color: rgb(28 50 74);
    text-align: center;
    font-size: 2rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
}

.sub-title-of-staff-name{
margin: 0px;
color: rgb(156 163 175);
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    font-family: Papyrus;
}

.med-spa-staff-info-p-tag{
    color: rgb(28 50 74);
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    font-size: 1.2rem;
}