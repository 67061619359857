.custom-logo {
    color: black;
    font-size: 34px;
    font-weight: 700;
    padding: 0 !important;
  }
  
  .custom-nav-links .nav-link {
    color: black;
    padding: 0;
  }

  .custom-nav-links-link-tag{
    color: rgba(0, 0, 0, 0.5);
    padding: 0px 8px;
  }

  .custom-toggle .navbar-toggler-icon,
  .custom-toggle .navbar-toggler-icon span {
    /* background-color: black; */
    background-color: white;
  }
  .main-web-nav {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 1);
    padding: 0px;
  }
  .custom-navbar {
    color: black;
  }
  
  .med-spa-custom-button{
      background-color: #1c324a;
      border-radius: 30px;
      border: none;
      font-size: 13px;
      color: white;
      padding: 8px;
      min-width: 130px;
  }

  .brand-logo-style{
    height: 75px;
    width: 180px;
    /* height: 50px;
    width: 115px; */
  }

  .custom-nav-links-link-tag:hover {
    color: #007bff; /* Set your desired link color on hover */
  }
  
  .custom-nav-links-link-tag.active {
    color: white; /* Set your desired active link color */
    background-color: #05b6b3;
    border-radius: 10px;
  }