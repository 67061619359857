.admin-full-calendar-bg{
    background-color: white;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 10px;
    height: auto;
}

.fc .fc-col-header-cell-cushion {
color: black;
/* display: none; */
text-decoration: none;
}

.fc .fc-button-primary{
    background-color: #CCDDEA;
    border: none;
    color: gray;
    text-transform: capitalize;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
    background-color: #2662F0;
    color: white;
    border: none;
    box-shadow: none;
}

/* .fc-timeGridDay-button, .fc-button fc-button-primary  */
.fc .fc-button:focus,.fc .fc-button-primary:focus{
    outline: none !important;
}

.fc .fc-button-primary:hover{
    background-color:#CCDDEA; 
    color: gray;
}

/* .fc-theme-standard td, 
.fc-theme-standard th 
{
background-color: white;
border: 1px solid gray;
} */

.fc-daygrid-day-frame, .fc-scrollgrid-sync-inner{
    background-color: white;
}

/* .fc-scroller-harness, */
.fc-scroller-liquid-absolute::-webkit-scrollbar {
  display: none;
}

.fc .fc-daygrid-day-number{
    text-decoration: none;
    color: black;
    font-size: 20px;
}

.fc-toolbar-chunk h2{
    background-color:#CCDDEA; 
    color: gray;
    font-size: 18px !important;
    padding: 5px;
    border-radius: 10px;
    
}
.fc-toolbar-title{
    /* font-weight: 400; */
}
