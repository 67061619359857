.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: 2px solid #1c324a;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    height: 100%;
    padding: 20px;
    border-radius: 20px;
  }
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button{
    display: none;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    background-color: white;
    color: #1c324a;
    font-size: 20px;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    background-color: gainsboro;
    border-radius: 10px;
  }
  
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: gray;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }

  .react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #1c324a;
    color: white;
  }
  
  .react-calendar__tile--now {
    background: #ffff76;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    /* background: #1087ff; */
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }

  .react-calendar .available {
    background-color: #008000d6;
    border: 1px solid white;
    color: white;
  }
  
  .react-calendar .unavailable {
    /* background-color: #ff0000ba; */
    /* background-color: gray; */
    background-color: gainsboro;
    border: 1px solid white;
    color: black;
  }
  
  @media screen and (max-width: 767px) {
    
    .react-calendar {
      width: 100%;
      max-width: 100%;
      background: white;
      border: 1px solid #1c324a;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 1.125em;
      height: 100%;
      padding: 5px;
      border-radius: 20px;
    }

    .react-calendar button {
      margin: 0;
      border: 0;
      outline: none;
      background-color: white;
      color: #1c324a;
      font-size: 16px;
  }

  .react-calendar__month-view__weekdays__weekday abbr{
    text-align: center;
    text-transform: uppercase;
    font: inherit;
    font-size: 9px;
    font-weight: bold;
}

.react-calendar__viewContainer{
  margin-top: -24px;
}
  }