/* 
.scroll-parent {
  overflow: hidden;
}

.flex-carousal-div {
  display: flex;
  gap: 10px;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

.flex-carousal-div.paused {
  animation-play-state: paused;
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
} */


.scroll-parent {
  overflow: hidden;
}

.flex-carousal-div {
  display: flex;
  gap: 10px;
  white-space: nowrap;
  animation: scroll 20s linear infinite;
}

.flex-carousal-div.paused {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
