.med-spa-article-card-in-service{
    border: none;
    padding-top: 20px;
}

.med-spa-article-card-in-service-title{
    color: rgb(28 50 74);
    font-weight: 500;
}

.med-spa-article-details-header-flex{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding-top: 20px;
    font-size: 14px;
}

.med-spa-article-details-header-flex-p{
    margin: 0px;
    color: rgb(107 114 128)

}

.med-spa-article-details-header-flex-title{
    margin: 0px;
    cursor: pointer;
    color: rgb(107 114 128)
}

.med-spa-articles-main-flex{
    width: 100%;
    max-height: 600px;
}

.article-table-of-content-card{
    padding: 1.25rem;
    background-color: rgb(249 250 251)
}

.med-spa-articles-para{
    font-size: 1.25rem;
    line-height: 1.8;
    color: #374151;
}

.med-spa-articles-header{
    font-size: 2.1em;
    margin-top: 1.5555556em;
    color: #111827;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.book-appt-head-line{
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    margin-bottom: 20px;
}
.interested-boo-appt-div{
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
}

.articles-card-body{
    height: 600px;
    border: 1px solid rgb(209 213 219);
    box-shadow: 0 0 0 #0000, 0 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }