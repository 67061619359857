/* Loader overlay styles */
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.6);  */
    /* background: rgb(255 249 249 / 51%); */
    background: rgb(255 249 249 / 88%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's above other content */
  }
  
  /* Loader content styles (centered) */
  .loader-content {
    background: transparent; /* Set the background of the loader */
    border-radius: 20px;
    padding: 20px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
    text-align: center;
  }
  
  .loader-content img {
    width: 200px; /* Adjust the width of your animation */
    height: 200px; /* Adjust the height of your animation */
  }
  