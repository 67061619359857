.fixed-navba-styles{
    position: fixed;
    width: 100%;
    background-color: white;
    z-index: 1;
}
.med-spa-section-one-bg{
    /* background-color: rgb(249 250 251 ); */
    background: linear-gradient(270deg, #e5e7eb, rgba(229,231,235,0));
    /* padding: 20px; */
}

.section-one-head-tag{
    font-size: 3rem;
    line-height: 1;
    color: rgb(28 50 74);
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
}

.section-one-para-tag{
    color: rgb(28 50 74);
}

.explore-treartment-tag{
    color: rgb(156 163 175);
    font-weight: 500;
    font-size: 2.25rem;
    text-align: center;
    font-family: Papyrus;
    /* font-family: Luxus,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji */
}

.explore-treartment-sub-title-tag{
    padding-top: 10px;
    color: rgb(28 50 74);
    text-align: center;
    font-size: 15px;
}

.explore-treartment-sub-title-head-tag{
    /* padding-top: 10px; */
    color: rgb(28 50 74);
    text-align: center;
    font-size: 3rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
}
.explore-treartment-sub-title-head-tag-signup{
    /* padding-top: 10px; */
    color: rgb(28 50 74);
    text-align: center;
    font-size: 3rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
}

.treatment-curousal-image{
    height: 380px;
    width: 100%;
}

.gradient-overlay {
    position: relative;
  }
  
  .gradient-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)); */
    background: linear-gradient(0deg, #1c324a, rgba(28,50,74,0));
    pointer-events: none;
  }

  .traetment-caption-title-caurousal{
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    font-size: 1.5rem;
    line-height: 2rem;
    color: rgb(255 255 255);
    text-align: left;
  }

  .traetment-caption-description-caurousal{
    color: rgb(255 255 255);
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: left;
  }

  .traetment-caption-button-caurousal{
    background-color: transparent;
    padding: 8px;
    color: rgb(255 255 255);
    border: 1px solid white;
    border-radius: 25px;
    text-align: left;
    font-size: .75rem;
    line-height: 1rem;
  }
  .traetment-caption-button-caurousal:hover{
    background-color: white;
    color: #1c324a;
  }

  .custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  display: none;
}
.treatment-category-buttons{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.nonselected-treatment-category{
    background-color: transparent;
    border: none;
    padding: 8px;
}
.selected-treatment-category{
    background-color: #1c324a;
    border: none;
    border-radius: 20px;
    padding: 8px;
    color: white;
    min-width: 100px;
}

.treatment-carousal-caption{
    right: 5%;
    left: 5%;
    bottom: 10px;
    padding-bottom: 0px;
    z-index: 0;
}

.medispa-treatment-carousel-controls{
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 15px;
    gap: 20px;
    align-items: center;
}

.custom-carousel-container {
    position: relative;
  }


.progress-bar {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px; /* Adjust the height as needed */
    border-radius: 0;
    background-color: #1c324a;
  }
  
  /* Styling for the seen progress in blue */
  .progress-bar-med-spa-bg-complete {
    background-color: #1c324a !important;
  }
  
  /* Styling for the remaining progress in red */
  .progress-bar-med-spa-bg-remain {
    background-color: white !important;
  }

  .med-spa-treat-curousal-navigators{
    color: #1c324a;
    height: 25px;
    width: 25px;
    cursor: pointer;
  }

  .section-image-quadra-shape{
    clip-path: polygon(18% 0%, 100% 0%, 80% 100%, 0% 100%);

  }

  .med-spa-sec-three-bg{
    background-color: #1c324a;
    color: white;
  }
  
  .section-three-head-tag{
    font-size: 3rem;
    line-height: 1;
    color: rgb(255 255 255);
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    max-width: 500px;
}

.section-three-para-tag{
    color: rgb(255 255 255);
    max-width: 500px;
}
.med-spa-custom-button-section-three{
    background-color: rgb(255 255 255);
    color: #1c324a;
    padding: 8px;
    min-width: 130px;
    border: none;
    border-radius: 20px;
}

.visit-us-p-tag-locations{
    font-size: 1.25rem;
    line-height: 4rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
    color: #1c324a;
    cursor: pointer;
}

.med-spa-footer-header-tag{
  color: rgb(249 250 251);
  font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 600;
    letter-spacing: .05em;
}

.med-spa-footer-para-tag{
  color: rgb(249 250 251);
  font-size: .875rem;
    line-height: 1.25rem;
    cursor: pointer;
}

.med-spa-second-footer{
  background-color: rgb(6 10 14);
}

.articles-carousal-bg{
  width: 100%;
    height: 100%;
    /* background: linear-gradient(0deg, #1c324a, rgba(28,50,74,0)); */
    
}


.image-container {
  position: relative;
}

.articles-carousal-image {
  width: 100%;
  height: 380px; /* Adjust the height as needed */
  object-fit: cover;
  /* background: linear-gradient(0deg, #1c324a, rgba(28, 50, 74, 0)); */
}

.image-title-article-carousal{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: linear-gradient(0deg, #1c324a, rgba(28, 50, 74, 0));
  color: #fff;
  text-align: center;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .explore-treartment-sub-title-head-tag-signup{
    /* padding-top: 10px; */
    color: rgb(28 50 74);
    text-align: center;
    font-size: 1.5rem;
    font-family: Prata,ui-serif,Georgia,Cambria,Times New Roman,Times,serif;
}
}

