/* .css-1f2xuhi-MuiDrawer-docked .MuiDrawer-paper{
  background-color: rgb(90, 47, 252);
}
.css-1l8j5k8{
  background-color: rgb(90, 47, 252);
} */

.background-screen-of-application {
  /* background-color: #F1F5F8; */
  padding-top: 10px;
  padding-bottom: 10px;
}
.digi-application-bg-color{
   background-color: #F1F5F8;
   padding-top: 15px;
   padding-bottom: 10px;
   height: 100vh;
}

.login-form-flex-div {
  display: flex;
  width: 100%;
  border-bottom: 1px solid white;
  gap: 10px;
  padding-bottom: 3px;
  margin-bottom: 20px;
}

.login-form-input-field {
  background-color: transparent;
  border: none;
  width: 100%;
  color: white;
}

.login-form-input-field::placeholder {
  color: white;
}

.login-form-input-field:focus {
  outline: none;
}

.login-form-input-logo {
  height: 22px;
  width: 21px;
}

.login-form-login-button {
  width: 100%;
  background-color: #74cd13;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 5px;
  margin-top: 10px;
}

.login-form-login-button:focus {
  outline: none;
}

.forgot-password-para-tag {
  margin: 0px;
  font-style: italic;
  font-size: 13px;
  color: white;
  text-decoration: underline;
  margin-top: 5px;
  cursor: pointer;
}

.login-form-register-div {
  text-align: center;
  padding-top: 15px;
}

.login-form-register-div p {
  color: white;
  font-size: 12px;
  margin: 0px;
}

.login-form-register-div button {
  background-color: #ebf5ff;
  width: 100%;
  color: #6665dd;
  border: none;
  border-radius: 10px;
  margin-top: 5px;
  padding: 5px;
}

.patient-registration-input-tag {
  background-color: #f4f5f8;
  color: #958a8a;
  border: 1px solid #958a8a;
  border-radius: 5px;
  width: 100%;
  /* margin-bottom: 10px; */
}
.patient-dashboard-dropdown {
  background-color: #f4f5f8;
  color: black;
  border: 1px solid #958a8a;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}
.patient-dashboard-dropdown:focus{
  outline: none;
}
.patient-registration-input-tag::placeholder {
  color: #958a8a;
}

.patient-registration-input-tag:focus {
  outline: none;
}

.round-border-div-of-data {
  border: 1px solid black;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.round-border-div-of-data-relative-infor {
  /* background-color: #F1F5F8; */
  background-color: white;
  position: absolute;
  margin-top: -29px;
  margin-left: 50px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 700;
}

.container-of-progress-of-patient-reg {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  flex-wrap: wrap;
}

.line-of-progress-of-patient-reg {
  height: 4px;
  background-color: #c2b5f5;
  flex: 1;
  margin: 0 10px;
}

.circle-of-progress-of-patient-reg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #5d33fc;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.patient-details-submit-button {
  background-color: #5d33fc;
  color: white;
  border: none;
  border-radius: 10px;
  /* width: 100%; */
  padding: 5px;
  min-width: 100px;
}
.validation-error-message {
  margin: 0px;
  color: red;
  font-size: 14px;
}
.custom-header-of-tables {
  background-color: #5a2ffc;
  color: white;
}
.custom-header-of-tables:hover {
  background-color: #5a2ffc;
}


  .ag-body-vertical-scroll-viewport::-webkit-scrollbar{
    display: none;
  }
  .ag-body-vertical-scroll{
    display: none;
  }
  .ag-body-horizontal-scroll-viewport{
    /* display: none; */
  }
  .ad-header-active{
    background-color: red;
  }


  .patient-dashboard-img{
    height: 80px;
    width: 80px;
  }

  .patient-basic-details-para{
    margin: 0px;
    font-weight: 600;
  }

  .patient-basic-details-para span{
    font-weight: 400;
  }
  .patient-dashboard-cards{
    height: 100%;
  }
  .med-spa-custom-button{
    background-color: #1c324a;
    border-radius: 30px;
    border: none;
    font-size: 13px;
    color: white;
    padding: 8px;
    min-width: 130px;
}

.appt-as-guest-input-field{
  width: 100%;
  border: none;
  border-bottom: 2px solid #1c324a;
  padding: 5px;
  margin-bottom: 10px;
}

.appt-as-guest-input-field:focus{
  outline: none;
}


/* .css-1f2xuhi-MuiDrawer-docked */
.MuiDrawer-paper::-webkit-scrollbar{
  display: none;
}

.patient-dashboard-img{
  height: 80px;
  width: 80px;
}

.patient-basic-details-para{
  margin: 0px;
  font-weight: 600;
}

.patient-basic-details-para span{
  font-weight: 400;
}
.patient-dashboard-cards{
  height: 100%;
}

.patient-details-by-admin-flex-tag{
  display: flex;
  justify-content: space-between;
  
}
.patient-details-by-admin-flex-tag-button{
border: none;
color: white;
background-color: #5d33fc;
width: 100%;

padding-top: 5px;
padding-bottom: 5px;
}

.patient-details-by-admin-flex-tag-button-active{
border: none;
color: #5d33fc;
background-color: white;
width: 100%;

padding-top: 5px;
padding-bottom: 5px;
}

.patient-details-h-tag{
/* margin: 0px; */
font-size: 19px;
}
.patient-details-p-tag{
margin: 0px;
}

.appointment-type-btn-active{
background-color: #5d33fc;
color: white;
border: none;
/* padding-top: 5px; */
padding: 5px;
width: 100%;
border-left: 1px solid white;
border-right: 1px solid white;
}

.appointment-type-btn-inactive{
  background-color: #c5c5c5;
color: black;
border: none;
/* padding-top: 5px; */
padding: 5px;
width: 100%;
border-left: 1px solid white;
border-right: 1px solid white;
}

.all-services-in-billing{
  display: flex;
  align-items: center;
  justify-content: space-between
}

/* styles.css */
.qr-billing-table{
  width: 100%;
  border: 1px dotted black;
  padding: 5px;
}

.qr-billing-table th, .qr-billing-table td {
  border: 1px dotted black;
  padding: 8px;
}
.billing-table {
  width: 100%;
  border: 1px solid black;
  padding: 8px;
}

.billing-table th, .billing-table td {
  border: 1px solid black;
  padding: 8px;
}

.billing-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.charges-info-div {
  padding-top: 10px;
}
.charges-info-div h2{
  font-size: 20px;
  margin: 0px;
  font-weight: 700;
}
.charges-info-div p{
  font-size: 18px;
  margin: 0px;
}

.charges-info-div label{
  font-size: 18px;
  margin: 0px;
  gap: 10px;
}
.register-patient-column-input {
  border: 1px solid #d1d5db;
  margin-bottom: 15px;
  padding: 5px;
  width: 100%;
}

.custom-checkbox{
  outline: 2px solid black;
  height: 20px;
  width: 20px;
  /* border: 2px solid red; */
  /* display: inline-block; */
  /* padding: 5px; */
  
}









/* Profile container for card layout */
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
}

.profile-card {
  max-width: 800px;
  border-radius: 20px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

/* Profile icon styling */
.profile-header {
  position: relative;
}

.profile-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #05b6b3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.profile-initials {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
}

/* Profile details styling */
.profile-card p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Edit button styling */
.edit-btn {
  border-radius: 30px;
  padding: 10px 30px;
  background-color: #05b6b3;
  border: none;
  font-size: 16px;
}

.edit-btn:hover {
  background-color: #048b91;
}









@media (max-width: 768px) {
  .patient-registration-input-tag {
    margin-bottom: 10px;
  }
  .round-border-div-of-data-relative-infor {
    margin-left: 0px;
  }
}
