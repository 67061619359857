.med-spa-support-screen-fix-main{
    z-index: 2147483647;
    top: auto;
    position: fixed;
    bottom: 20px;
    right: 20px;

}
.med-spa-support-screen-fix{
    background-color: #003b5c;
    width: 200px;
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.1s ease;
}

.med-spa-support-screen-fix:hover {
    transform: scale(1.1); /* Scale the div by 10% on hover */
  }

.med-spa-support-screen-logo-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.med-spa-support-screen-logo{
    height: 35px;
    width: 35px;
}

  
  .chat-window {
    position: absolute;
    padding: 10px;
    top: auto;
    right: 0;
    width: 350px; /* Adjust the width as needed */
    /* height: 400px; */
    height: 560px;
    background-color: #ffffff;
    box-shadow: -3px 0px 5px rgba(0, 0, 0, 0.2); /* Add a shadow */
    overflow-y: auto; /* Add scrolling if content exceeds height */
    bottom: 50px;
    border-radius: 20px;
  }

  .support-card-in-chat-window p{
    margin: 0px;
  }

  .support-card-in-chat-window {
    cursor: pointer;
    transition: border-top-color 0.3s ease; /* Add a transition effect to the top border color */
    padding: 10px;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */
    height: 100%; 
    box-shadow: -3px 0px 5px rgba(0, 0, 0, 0.2); 
    min-width: 60px;
  }

  .support-card-in-chat-window-active{
    background-color: #003b5c;
    color: #FFFFFF;
    cursor: pointer;
    transition: border-top-color 0.3s ease; /* Add a transition effect to the top border color */
    padding: 10px;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */
    height: 100%; 
    box-shadow: -3px 0px 5px rgba(0, 0, 0, 0.2); 
    min-width: 60px;
  }
  
  .support-card-in-chat-window:hover {
    /* border-top-color: 2px solid green;  */
    border-top: 4px solid green;

  }
.chat-boat-text-us-input-row{
  position: fixed;
  bottom: 72px;
  background-color: white;
  width: 330px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: 2px solid #003b5c;
  padding-top: 5px;
}

.chat-boat-text-us-input-row-input{
  border: none;
  width: 300px;
  /* border: 1px solid rgb(209 213 219); */
}

.chat-boat-text-us-input-row-input:focus{
  outline: none;
}
/* 
.chat-message.user-message{
background-color: red;
}

.chat-message.bot-message{
background-color: blue;
margin-top: 5px;
margin-bottom: 5px;
} */

.chat-history {
  display: flex;
  flex-direction: column;
  max-height: 420px; /* Adjust the max height as needed */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
}

.chat-history::-webkit-scrollbar{
  display: none;
}

/* User message style (right side) */
.chat-message.user-message {
  background-color: #0084FF; /* Blue background for user messages */
  color: #FFFFFF; /* White text color for user messages */
  align-self: flex-end; /* Right-align user messages */
  margin: 5px 0px; /* Margin for user messages */
  padding: 10px; /* Padding for user messages */
  border-radius: 10px; /* Rounded corners for user messages */
  max-width: 70%; /* Adjust as needed */
}

/* Bot message style (left side) */
.chat-message.bot-message {
  background-color: #E5E5E5; /* Light gray background for bot messages */
  color: #000000; /* Black text color for bot messages */
  align-self: flex-start; /* Left-align bot messages */
  /* margin: 5px 10px;  */
  padding: 10px; /* Padding for bot messages */
  border-radius: 10px; /* Rounded corners for bot messages */
  max-width: 70%; /* Adjust as needed */
}

.bot-message-for-pending{
  background-color: #E5E5E5; /* Light gray background for bot messages */
  color: #000000; /* Black text color for bot messages */
  align-self: flex-start; /* Left-align bot messages */
  /* margin: 5px 10px;  */
  padding: 10px; /* Padding for bot messages */
  border-radius: 10px; /* Rounded corners for bot messages */
  max-width: 70%; /* Adjust as needed */
}


.send-query-button {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.call-now-div-bg{
  display: flex;
  justify-content: center;
  padding: 10px;
  
}

.call-now-div-bg-style{
  background-color: #e5e7eb;
  padding: 25px;
  border-radius: 40px;
  box-shadow: 0 2px 6px rgba(38,37,40,0.1);
}

.call-now-div-logo{
  color: #003b5c;
  height: 25px;
  width: 25px;
}

.give-us-a-call-main-div{
  background-color: #e5e7eb;
  text-align: center;
  margin-top: -45px;
  padding-top: 36px;
  padding-bottom: 10px;
}

.give-us-a-call-main-div h3{
  margin: 0px;
  font-size: 20px;
}

.give-us-a-call-main-div p{
  margin: 0px;
}

.call-now-button-div{
  background-color: #e5e7eb;
  padding: 10px;
  margin-top: 10px;
}

.call-now-button-div-button{
  width: 100%;
  background-color: #003b5c;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.text-your-query{
  width: 100%;
  margin-top: 5px;
  border: 1px solid rgb(209 213 219);
    box-shadow: 0 0 0 #0000, 0 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding: 5px;
    margin-bottom: 10px;
}

  @media (max-width: 425px) {
    .chat-window {
      position: absolute;
      padding: 10px;
      top: auto;
      right: 0;
      width: 280px; /* Adjust the width as needed */
      height: 350px;
      background-color: #ffffff;
      box-shadow: -3px 0px 5px rgba(0, 0, 0, 0.2); /* Add a shadow */
      overflow-y: auto; /* Add scrolling if content exceeds height */
      bottom: 50px;
      border-radius: 20px;
    }

    .chat-boat-text-us-input-row-input{
      /* width: 100%; */
      border: none;
      width: 230px;
      border: 1px solid rgb(209 213 219);
    }

    .chat-boat-text-us-input-row{
      position: fixed;
      bottom: 72px;
      background-color: white;
      width: 260px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }