.register-patient-column{
    text-align: center;
}

.register-patient-column-input{
    /* min-width: 300px; */
    width: 100%;
    margin-bottom: 15px;
    padding: 5px;
    border: 1px solid rgb(209 213 219);
}

.med-spa-pat-reg-bg-colo-of-page{
    background-color: rgb(249 250 251);
}

.error-message{
    margin-top: -15px;
    color: red;
    text-align: left;
}

.password-strength-bar {
    height: 8px;
    background-color: #eee;
    margin-top: 5px;
  }
  
  .password-strength-progress {
    height: 100%;
    background-color: #4caf50; /* Green color for strong passwords */
  }
  

  .patient-registration-input-tag {
    background-color: #f4f5f8;
    color: #958a8a;
    border: 1px solid #958a8a;
    border-radius: 5px;
    width: 100%;
    /* margin-bottom: 10px; */
  }
  .patient-dashboard-dropdown {
    background-color: #f4f5f8;
    color: black;
    border: 1px solid #958a8a;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
  }
  .patient-dashboard-dropdown:focus{
    outline: none;
  }
  .patient-registration-input-tag::placeholder {
    color: #958a8a;
  }
  
  .patient-registration-input-tag:focus {
    outline: none;
  }
  
  .round-border-div-of-data {
    border: 1px solid black;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .round-border-div-of-data-relative-infor {
    /* background-color: #F1F5F8; */
    background-color: white;
    position: absolute;
    margin-top: -29px;
    margin-left: 50px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 700;
  }

  .validation-error-message {
    margin: 0px;
    color: red;
    font-size: 13px;
  }